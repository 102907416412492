<template>
    <recess-modal modal-size="small"
        variant="variant2"
        :show-modal="isModalVisible"
        @close="close"
    >
        <template slot="modal-title">
            <h3 class="u-fw-semi-bold">{{ PROVIDER.Portfolio.PortfolioOverview.Popup.DeleteCourse.Title }}</h3> 
        </template>
        <template slot="modal-body">
            <p>{{ PROVIDER.Portfolio.PortfolioOverview.Popup.DeleteCourse.Description.PartOne }}</p>
            <p
                v-if="courseIsInAssortment"
            >{{ PROVIDER.Portfolio.PortfolioOverview.Popup.DeleteCourse.Description.PartTwo }}</p>
        </template>
        <template slot="modal-footer">
            <div class="text-right">
                <recess-button
                    variant="secondary"
                    :title="BUTTON_TEXT.delete"
                    class="mr-3 qa-modal-delete-course-button"
                    @click.native.prevent="deleteCourse()"
                />
                <recess-button
                    variant="tertiary"
                    :title="BUTTON_TEXT.cancel"
                    class="qa-modal-delete-course-cancel-button"
                    @click.native.prevent="close()"
                />
            </div>
        </template>
    </recess-modal>
</template>

<script>
import { BUTTON_TEXT, API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { deleteItem } from '@/../../shared/api/SharedClient'
import { defaultNotFoundErrorMessage, courseSuccessAction, defaultErrorMessage } from '../../../../shared/constants/validationMessageHelper'

export default {
    name: 'DeleteCoursePopupComponent',
    props: {
        isModalVisible: {
            type: Boolean,
            default: false
        }, 
        hasCourseDetails: {
            type: Object,
            default: () =>  {}
        }
    },
    data() {
        return {
            BUTTON_TEXT,
            PROVIDER,
            isTouched: false,
            isDeleted: false,
            course: null
        }
    },
    computed: {
        courseIsInAssortment() {
            return this.hasCourseDetails && this.hasCourseDetails.isInAssortment
        }
    },  
    methods: {
        async deleteCourse() { 
            if (!this.hasCourseDetails.courseId) return 
            
            try {
                await deleteItem(
                    process.env.VUE_APP_PROVIDER_API_URL,
                    `${API_CALL_URL_PATHS.courses}/${this.hasCourseDetails.courseId}`,
                    false
                )  

                const toastNotification = {
                    type: 'success',
                    message: courseSuccessAction('verwijderd')
                } 
                
                this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                    root: true
                })                    
 
                this.close() 
                    let timeoutValue = 3000;
                    if(process.env.NODE_ENV !== 'production'){
                        timeoutValue = 10000
                    };
                    
                    setTimeout(() => {
                        if(this.hasCourseDetails.isInCourseDetailsPage) {
                            this.$router.push('/portfolio/overview')
                        } else {
                            this.$bus.emit('refreshCourseList')
                        }
                    }, timeoutValue)

            } catch (error) { 
                const toastNotification = {
                    type: 'error',
                    message: null
                }

                if (error.response.data?.error?.code === '404') {
                    toastNotification.message = defaultNotFoundErrorMessage('leeraanbod')
                } else {
                    toastNotification.message = defaultErrorMessage
                } 
                
                this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                    root: true
                })  
            }
        },
        close() {
            this.$emit('close')
        }
    }
}
</script>
